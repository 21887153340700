.header {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}
.container {
  flex: 1;
  /* background-color: "red"; */
}
.footer {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}
.nav-link {
  color: #825d37;
  font-weight: bold;
}
.btn.btn-primary {
  background: #825d37;
  border-color: #825d37;
  outline: none;
  border: none;
  box-shadow: none;
}
.my-icon {
  font-size: 30px;
  color: #825d37;
  padding: 3px 4px;
}
.textColor {
  color: #825d37;
}
.texthover:hover {
  color: #825d37;
}
.LinkColor {
  color: #212529;
  font-size: 14px;
}
.downloadlink:hover {
  color: #825d37;
  font-size: 14px;
  text-decoration: underline;
}
.categoriesScroll {
  width: 100%;
  overflow: scroll;
}
.topMargin {
  margin-top: 75px;
}
li.list-group-item {
  display: flex;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.my-nav-link {
  color: #212529;
  font-weight: bold;
  text-decoration: none;
}
.logo {
  margin-top: 8%;
}
/* @media (min-width:768px){ */
.card-img-top-name {
  width: auto !important;
  margin: 0 auto !important;
}
/* } */
@media (max-width: 767px) {
  .card-img-top-name {
    max-width: 100% !important;
    margin: auto !important;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pTag {
  margin: 0px;
  padding: 0px;
}
.noScroll {
  overflow: hidden;
}
.image-hover {
  transition: transform 0.3s ease;
}

.image-hover:hover {
  transform: scale(1.1);
}
.horizontalContainer p {
  margin-bottom: 4px;
}
.horizontalContainer1 {
  display: flex;
}
.horizontalContainer1 p,
.horizontalContainer1 i {
  margin-bottom: 0px;
  font-size: 0.8rem;
}
.horizontalContainer {
  display: flex;
  /* justify-content: space-between; */
}
.logininputfield,
.loginlabel,
.getotpbutton {
  width: 70%;
  margin: 2% 15%;
}
.varient_percentage {
  position: relative;
  background-color: #538cee;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  p {
    font-size: 10px;
    color: white;
    text-align: center;
  }
}
.item {
  width: auto;
  padding: 0% 1%;
  margin: 1%;
  text-align: center;
  border-bottom-width: 2px;
}
.itemColor {
  border-color: #47bd67;
}
.address {
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-bottom-width: 2px;
  margin-top: 4px;
}
.addressSelect {
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-bottom-width: 2px;
  margin-top: 4px;
  border-color: #47bd67;
}
.cart {
  height: 18px;
  width: 18px;
  border-radius: 10px;
  text-align: center;
  border: 1.5px solid #e4f9fd;
  background-color: #e4f9fd;
  margin-left: -6px;
  margin-top: -4px;
  font-size: 10px;
  color: #825d37;
  font-weight: 500;
}
.orderDetailsStyle {
  display: flex;
  justify-content: space-between !important;
  padding-left: 20px !important;
  padding-right: 85px !important;
}
.profileCard {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
  padding: 2rem;
}
.AddAddress {
  width: 100%;
  background: #825d37;
  padding: 6px;
  color: #f1f1f1;
  font-weight: 500;
  border-radius: 2px;
}
/* #searchDetails {
  display: none;
} */
/* ::after, ::before {
  box-sizing: inherit;
} */
.Anchor {
  text-decoration: none;
}

.imgSlider {
  height: 300px;
  margin-top: 10px;
}
/* @media (min-width: 1391px) {
  .swiper-slide {
    width: 16% !important;
  }
} */
/* @media (max-width: 1390px) {
  .swiper-slide {
    width: 26% !important;
  }
} */
/* @media (max-width: 860px) {
  .swiper-slide {
    width: 30% !important;
  }
} */
@media (max-width: 660px) {
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: flex-start !important;
    transition-property: transform;
    transition-timing-function: var(
      --swiper-wrapper-transition-timing-function,
      initial
    );
    box-sizing: content-box;
  }
  /* .swiper-slide {
    width: 42% !important;
  } */
}
@media (max-width: 500px) {
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: flex-start !important;
    transition-property: transform;
    transition-timing-function: var(
      --swiper-wrapper-transition-timing-function,
      initial
    );
    box-sizing: content-box;
  }
  /* .swiper-slide {
    width: 50% !important;
  } */
}
@media (max-width: 767px) {
  .singaleContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .loginformbanner {
    display: none;
  }
  .imgSlider {
    height: 100px;
    max-width: 768px;
    margin-top: 10px;
  }

  .pdf {
    height: 900px;
  }
  .SubCategoriesProduct {
    width: 20%;
    padding-right: 0px;
  }
  .ProductCategories {
    width: 80%;
    padding-left: 0px;
    padding-bottom: 10px;
  }
  li.list-group-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .texthover {
    text-overflow: ellipsis;
    text-align: center;
  }
  .selectedimage_singleproduct {
    cursor: pointer;
    margin: 10px;
    border-radius: 5px;
    width: 80px;
    height: 80px;
  }
}

@media (min-width: 769px) {
  .hrTag {
    display: none;
  }
  .selectedimage_singleproduct {
    cursor: pointer;
    margin: 5% 10%;
    border-radius: 5px;
    width: 60%;
    height: 20%;
  }
}
@media (max-width: 479px) {
  .imgSlider {
    height: 160px;
    max-width: 479px;
    margin-top: 10px;
  }
  .pdf {
    height: 500px;
  }
  .singaleContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .imageContainer {
    height: 500px;
  }
}
@media (min-width: 990px) {
  .btn_offcanvas,
  .searchInput_fild,
  .logoheader1,
  .loginbutton,
  .profileicon1 {
    display: none;
  }

  .navbar {
    flex-wrap: nowrap;
  }
  .navbar-nav {
    align-items: center;
  }
}
@media (max-width: 990px) {
  .logoheader,
  .profileicon,
  .registerbutton,
  .searchInput_fild1 {
    display: none;
  }
}
@media (max-width: 500px) and (min-width: 100px) {
  .searchInput_fild {
    width: 40%;
  }
}
@media (max-width: 470px) and (min-width: 100px) {
  .multiImage {
    display: flex;
    overflow-x: scroll;
    width: 15rem;
    height: auto !important;
    margin: 2px;
  }
}
@media (min-width: 769px) {
  .multiImage {
    overflow-y: scroll;
    margin: 2px;
    height: 25rem;
  }
}
@media (max-width: 520px) and (min-width: 470px) {
  .multiImage {
    display: flex;
    width: 20rem;
    overflow-x: scroll;
    margin: 2px;
    height: auto !important;
  }
}
@media (max-width: 560px) and (min-width: 521px) {
  .multiImage {
    display: flex;
    width: 23rem;
    overflow-x: scroll;
    margin: 2px;
    height: auto !important;
  }
}
@media (max-width: 767px) and (min-width: 561px) {
  .multiImage {
    display: flex;
    width: 28rem;
    overflow-x: scroll;
    margin: 2px;
    height: auto !important;
  }
}
@media (max-width: 470px) {
  .SubCategoriesProduct {
    width: 20%;
  }
  .ProductCategories {
    width: 80%;
    padding-bottom: 10px;
  }
  .promocode {
    width: 60%;
    padding: 0.5em;
    margin: 1% 3%;
  }
  .applybutton {
    width: 30%;
    font-size: 0.8rem;
    padding: 0.5rem;
    margin: 1%;
    /* padding: 0.2rem; */
  }
}
@media (min-width: 470px) {
  .promocode {
    width: 70%;
    padding: 0.5rem;
    margin: 0% 2%;
  }
  .applybutton {
    padding: 0.5rem;
    width: 20%;
  }
}
@media (max-width: 767px) {
  .checkX {
    padding: "10px";
    display: "inline-block";
    width: "calc(30% - 20px)";
  }
  .mobileviewcategory {
    width: 20%;
  }
}
@media (max-width: 450px) {
  .SubCategoriesProduct {
    width: 20%;
    padding-right: 0px;
  }
  .ProductCategories {
    width: 80%;
    padding-left: 0px;
    padding-bottom: 10px;
  }
}
@media (max-width: 730px) {
  .checkoutdetail {
    flex-direction: row;
    margin-top: 10px;
    padding: 2px 5px;
    justify-content: space-between;
    align-items: center;
  }
  .checkoutimage {
    width: 90px;
    height: 80px;
    border-radius: 5px;
    margin-right: 1rem !important;
  }
}
@media (max-width: 600px) {
  .bannerheight {
    height: 12rem;
  }
}
@media (max-width: 450px) {
  .bannerheight {
    height: 10rem;
  }
}
@media (min-width: 730px) {
  .checkoutdetail {
    flex-direction: row;
    margin-top: 10px;
    padding: 2px 5px;
    justify-content: space-around;
    align-items: center;
  }
  .checkoutimage {
    width: 90px;
    height: 80px;
    border-radius: 5px;
  }
}
.iconClass {
  height: 25%;
  position: absolute;
  right: 2%;
  top: 3%;
}
.whatsappButtonContainer {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
}
.pageNotFound {
  margin: 10px;
  margin-bottom: 40px;
}

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure at least the full height of the viewport */
}

.fixed-footer {
  margin-top: auto; /* Push the footer to the bottom of the page */
  background-color: #f0f0f0; /* Set a background color for the footer */
  padding: 10px; /* Add padding as needed */
}
.categories-container {
  display: flex;
  overflow-x: auto;
  background-color: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 1;
  height: 50px; /* Adjust the height as needed */
  width: 100%; /* Set the width to 100% or a specific value */
}

.categories-container::-webkit-scrollbar {
  display: none;
}

.category-item {
  padding: 5px;
  white-space: nowrap;
  margin-right: 10px;
  margin-left: 10px;
}

.category-item span {
  font-size: 14px; /* Adjust the font size as needed */
  display: inline-block; /* Ensure the text stays on one line */
}

.slider-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swiper-button-next,
.swiper-button-prev {
  height: 2.5rem;
  color: #fff;
  margin: 0.1rem;
  margin-top: -20px;
  background-color: #825d37;
  border: 0;
  border-radius: 3px;
  padding: 8px;
  transition: opacity 0.15s ease;
}
.swiper-button-next1,
.swiper-button-prev1 {
  color: #fff;
  background-color: #825d37;
  border: 0;
  padding: 10px;
  transition: opacity 0.15s ease;
}
.swiper-slide.category-item {
  width: auto !important;
  padding: 0rem;
  height: 2rem;
  padding-top: 0.5rem;
}
.swiper {
  width: 100% !important;
  margin-left: 0;
  margin-right: 0;
  justify-content: center;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  transition-property: transform;
  transition-timing-function: var(
    --swiper-wrapper-transition-timing-function,
    initial
  );
  box-sizing: content-box;
}

/* home slider next previous button css */

button.carousel-control-prev {
  justify-content: start;
  width: 4%;
  color: #fff;
  text-align: center;
}
button.carousel-control-next {
  justify-content: end;
  width: 4%;
  color: #fff;
  text-align: center;
}
.imageAdd {
  cursor: pointer;
  width: 98%; /* Example width */
  height: 6pc; /* Example height */
  margin: 10px; /* Example margin */
}

@media (max-width: 549px) {
  .imageAdd {
    cursor: pointer;
    width: 98%; /* Example width */
    height: 4pc; /* Example height */
    margin: 5px; /* Example margin */
  }
}
.product_varient {
  display: flex;
  width: 90%;
  overflow-x: scroll;
}
@media (min-width: 1163px) {
  img.singleproductimage {
    height: 350px !important;
    width: 100% !important;
    margin-top: 5px;
  }
}

@media (min-width: 767px) and (max-width: 1162px) {
  img.singleproductimage {
    height: 340px !important;
    width: 100%;
    margin-top: 5px;
  }
}
@media (min-width: 568px) and (max-width: 766px) {
  img.singleproductimage {
    height: 400px !important;
    width: auto !important;
    margin: 0 auto !important;
  }
}
@media (min-width: 300px) and (max-width: 568px) {
  img.singleproductimage {
    height: 350px !important;
    width: 100% !important;
    margin: 0 auto !important;
  }
}

.about_us {
  margin-top: 1rem;
  h6 {
    text-align: left;
    font-size: 1.8rem;
    color: #825d37;
  }
  p {
    text-align: justify;
    font-size: 1.2rem;
  }
  .store-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .store-info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .store-info i {
    font-size: 18px;
    margin-right: 10px;
    color: #825d37;
  }

  .store-details {
    font-size: 14px;
    text-align: left;
    border: 1px solid #825d37;
    border-radius: 5px;
    padding: 0.5rem;
  }

  .store-details p {
    margin: 3px 0;
    a {
      text-decoration: none !important;
      color: black;
    }
  }

  .store-details small {
    font-size: 12px;
    color: #777;
  }
}

/* shipping and refund policies */
.shippingcontainer {
  margin-bottom: 1rem;
  h2 {
    color: #825d37;
    border-bottom: 1px solid #825d37;
    padding-bottom: 10px;
  }
  .policy-section h3 {
    color: #825d37;
    font-size: 1.3rem;
  }
  h3 {
    color: #825d37;
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  a {
    color: #825d37;
    text-decoration: none !important;
    font-weight: 500;
  }
  hr {
    border: 1px solid #825d37;
  }
}
.wholesaledescription {
  table {
    width: 100% !important;
    margin: auto;
    border: 2px solid black;
    text-align: center;
    td,
    th {
      border: 1px solid black;
      font-size: 1.2rem;
    }
    th {
      background-color: #825d37;
      color: white;
    }
  }
}
.pdfcontainer {
  /* width: 70% !important; */
  margin: auto;
  padding: 1rem 0rem;
  border: 2px solid black;
  p {
    font-size: 1.5rem;
  }
  .qr {
    width: 60%;
    margin: auto;
    height: 270px;
  }
  .logo {
    background-color: #dbdbdb;
    width: 80%;
    margin: 1rem auto;
    padding: 1rem;
  }
  a {
    font-size: 1.5rem;
  }
}
